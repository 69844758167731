import React from 'react';
import "./Report.scss";
import { Button, TextField } from '@mui/material';
import Dashhead from '../Dashhead';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from 'axios';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import { connect } from 'react-redux';

function Settlement(props) {
    const [display, setDisplay] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedDate2, setSelectedDate2] = React.useState(null);

    // Define the minimum selectable date
    const minDate = new Date('2024-08-16');

    const handleDownload = async () => {
        try {
            const fromDate = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : null;
            const toDate = selectedDate2 ? moment(selectedDate2).format('DD-MM-YYYY') : null;

            // Step 1: Get afpId from the first API call
            // const userResponse = await axios.get(`https://prod.cleverpe.com/users/merchant/${props.user.userInfo.mobileNo}`, {
            //     headers: {
            //         'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWRtaW4iLCJtb2JpbGVObyI6Ijg4ODg4ODg4ODgiLCJpYXQiOjE3MjIzNTExNjIsImV4cCI6MTc1MzkwODc2Mn0.gJhbfx01iziLEYJjfmy6g7QoodOie48PcFpcpyo0tdk'
            //     }
            // });

            // const afpId = userResponse.data.result.data.afpId;

            // Step 2: Get partner _id using the afpId
            const partnerResponse = await axios.get(`https://prod.cleverpe.com/users/partner?name=${props.user.userInfo.groupId}`, {
                headers: {
                    'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWRtaW4iLCJtb2JpbGVObyI6Ijk5OTk5OTk5OTkiLCJpYXQiOjE3MjE0OTI3ODEsImV4cCI6MTc1MzA1MDM4MX0.MRlxhqwmLvHgQXDF_1Qsh42LNlgSi6mpiu17NG5LpTc'
                }
            });

            const partnerId = partnerResponse.data.result.data._id;

            // Step 3: Include the partnerId in the download request
            let url = `https://prod.cleverpe.com/order?show=xlsx&partnerId=${partnerId}`;

            if (fromDate) {
                url += `&from=${fromDate}`;
            }

            if (toDate) {
                url += `&to=${toDate}`;
            }

            var config = {
                method: 'get',
                url: url,
                responseType: 'blob', // Ensure the response is treated as a blob
                headers: {
                    'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWRtaW4iLCJtb2JpbGVObyI6Ijg4ODg4ODg4ODgiLCJpYXQiOjE3MjIzNTExNjIsImV4cCI6MTc1MzkwODc2Mn0.gJhbfx01iziLEYJjfmy6g7QoodOie48PcFpcpyo0tdk'
                }
            };

            axios(config)
                .then(function (response) {
                    // Create a Blob from the response data
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Trigger the download using FileSaver
                    FileSaver.saveAs(blob, 'settlement.xlsx');
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.error("Error downloading the file", error);
        }
    };

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <Dashhead id={6} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container">
                <form className="form">
                    <h2 className="mb-3">Export Completed Transaction Dump (Excel)</h2>
                    <div className="row m-auto">
                        <section className="my-2 col-6">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    inputFormat="dd/MM/yyyy"
                                    value={selectedDate}
                                    minDate={minDate} // Set minimum date for Start Date
                                    onChange={(newValue) => setSelectedDate(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </section>

                        <section className="my-2 col-6">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="End Date"
                                    inputFormat="dd/MM/yyyy"
                                    value={selectedDate2}
                                    minDate={minDate} // Set minimum date for End Date
                                    onChange={(newValue) => setSelectedDate2(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </section>
                    </div>
                    <div className="my-3" style={{ textAlign: "center" }}>
                        <Button onClick={handleDownload} variant="contained">Download Excel</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

const mapStateToProps = ({ EventUser }) => {
    return {
        user: EventUser
    };
};

export default connect(mapStateToProps)(Settlement);
